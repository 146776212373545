<template>
  <section class="service">
    <div class="banner">

      <!-- <img src="../../assets/images/huiyiyicheng_banner@2x.png" alt=""> -->

      <img
        :src="$t('locale') == 'zh' ? banner : banner_en"
        alt=""
      />
    </div>
    <section class="main_contain">
      <figure>

        <img
          style="width: 100%; height: 100%;padding-top:100px;padding-bottom:100px;"
          :src="$t('locale') == 'zh' ? agendas : agendas_en"
          alt=""
        />
      </figure>

      <FooterC></FooterC>
    </section>
  </section>
</template>

<script>
import FooterC from "./components/FooterC";

export default {
  components: {
    FooterC,
  },
  data() {
    return {
      banner: require("../../assets/zh/images/bannerac.png"),
      banner_en: require("../../assets/en/images/bannerac_en.png"),

      agendas: require("../../assets/zh/images/allac.png"),
      agendas_en: require("../../assets/en/images/allac_en.png"),
    };
  },
  methods: {
    handleClick(tab, event) {
      console.log(this.activeName);
      console.log(tab, event);
    },
  },
};
</script>

<style lang="scss" scoped>
.service {
  margin-top: 70px;
  background-color: #fff;
  word-break: break-word !important;

  .banner {
    width: 100%;
    position: relative;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .main_contain {
    width: 100%;  
    img{
      width: 100%;
      height: 100%;
    }
  }
}
</style>
